@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-orange: #f08517;
  --primary-black: #04101c;
  --secondary-green: #25a772;
  --secondary-blue: #0289f1;
  --secondary-purple: #6d00c1;
  --secondary-red: #d80047;
  --dark-blue: #092a43;
  --dark-blue-80: #3a5569;
  --dark-blue-60: #6b7f8e;
  --dark-blue-40: #9daab4;
  --dark-blue-20: #ced4d9;
  --dark-grey: #8c8e91;
  --medium-grey: #dcddda;
  --light-grey: #e8e8e5;
  --lighter-grey: #f4f4f6;
  --lightest-grey: #f7f6f7;
  --bar-width: 16px;
  --bar-height: 2px;
  --hamburger-gap: 4px;
  --animation-timing: 200ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
  --x-width: calc(var(--hamburger-height) * 1.41421356237);

  --max-content-width: calc(1440px - 160px);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

@font-face {
  font-family: Miller;
  src: url("../../public/fonts/miller-banner-semi-bold.woff") format("woff");
}

@font-face {
  font-family: Acumin;
  src: url("../../public/fonts/acuminpro-light.woff") format("woff");
}

html {
  font-size: 16px;
}

@layer base {
  .heading1 {
    font-family: Miller, "Times New Roman", Times, serif;
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 4.5rem;
  }
  .heading2 {
    font-family: Miller, "Times New Roman", Times, serif;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.875rem;
  }
  .heading3 {
    font-family: Acumin, Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 3.125rem;
  }

  .mobileH1 {
    font-family: Acumin, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5rem;
  }

  .mobileH2 {
    font-family: Acumin;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
  }

  .mobileH3 {
    font-family: Acumin;
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  .mobileButton {
    font-family: Acumin;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .maxContentWidth {
    max-width: var(--max-content-width);
  }

  h1 {
    @apply mobileH1 md:heading1;
  }
  h2 {
    @apply mobileH2 md:heading2;
  }
  h3 {
    @apply mobileH3 md:heading3;
  }
}

@layer components {
  .navShadow {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
  }
  .imageShadow {
    box-shadow:
      0px 8px 17px 0px rgba(0, 0, 0, 0.1),
      0px 32px 32px 0px rgba(0, 0, 0, 0.09),
      0px 71px 43px 0px rgba(0, 0, 0, 0.05),
      0px 127px 51px 0px rgba(0, 0, 0, 0.01),
      0px 198px 56px 0px rgba(0, 0, 0, 0);
  }
  .navBlurEffect {
    &:before {
      content: "";
      position: absolute;
      backdrop-filter: blur(4px);
      mask: linear-gradient(black 50%, transparent);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }
  .navActiveEffect {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 5px;
      left: 0;
      bottom: 0;

      background-color: transparent;
      transform: scale3d(0, 1, 0);
      transition-duration: 0.3s;
      transition-property: transform background-color;
    }
  }
  .active {
    &::after {
      transform: scale3d(1, 1, 1);
      background-color: var(--primary-orange);
    }
  }
  .footerGridTemplate {
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
  }

  .hamburgerMenu {
    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    width: fit-content;
    min-width: var(--bar-width);
    padding: 2rem;
    cursor: pointer;
  }

  .hamburgerMenu:has(input:focus-visible) {
    outline: 2px solid var(--primary-orange);
    border-radius: 2px;
  }

  .hamburgerMenu::before,
  .hamburgerMenu::after,
  .hamburgerMenu input {
    width: var(--bar-width);
    height: var(--bar-height);
    transform-origin: left center;
    transition:
      opacity var(--animation-timing),
      width var(--animation-timing),
      rotate var(--animation-timing),
      translate var(--animation-timing);
  }

  .hamburgerMenu::after {
    --one-third: calc(var(--bar-width) / 3);
    width: calc(var(--bar-width) - var(--one-third));
  }

  .hamburgerMenu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
  }

  .hamburgerMenu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 4px calc(var(--bar-height) / -2);
  }

  .hamburgerMenu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 4px calc(var(--bar-height) / 2);
  }
  .hamburgerMenu > input:checked {
    opacity: 0;
    width: 0;
  }
}

@layer utilities {
  .scrollbar-hide {
    /* IE and Edge */
    -ms-overflow-style: none;

    /* Firefox */
    scrollbar-width: none;

    /* Safari and Chrome */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .buttons {
    @apply buttonsMobile md:buttonsDesktop;
  }

  .buttonsSmall {
    @apply buttonsSmallMobile md:buttonsSmallDesktop;
  }

  .buttonsExtraSmallDesktop {
    font-family: "Acumin";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.875rem;
  }

  .buttonsSmallDesktop {
    font-family: "Acumin";
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  .buttonsSmallMobile {
    font-family: "Acumin";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .buttonsDesktop {
    font-family: "Acumin";
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .buttonsMobile {
    font-family: "Acumin";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  .copyLarge {
    @apply mobileCopyLarge md:desktopCopyLarge;
  }

  .copyMedium {
    @apply mobileCopyMedium md:desktopCopyMedium;
  }

  .copySmall {
    @apply mobileCopySmall md:desktopCopySmall;
  }

  .mobileCopyLarge {
    font-family: Acumin, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .mobileCopyMedium {
    font-family: "Acumin";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .mobileCopySmall {
    font-family: "Acumin";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .desktopCopyLarge {
    font-family: Acumin, Arial, Helvetica, sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 3.4375rem;
  }
  .desktopCopyMedium {
    font-family: Acumin, Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.25rem;
  }
  .desktopCopySmall {
    font-family: Acumin, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.25rem;
  }
  .btnText {
    font-family: Acumin, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5rem;
  }
  .fontMiller {
    font-family: Miller, Arial, Helvetica, sans-serif;
  }
  .fontAcumin {
    font-family: Acumin, Arial, Helvetica, sans-serif;
  }
}

/* Mantine NativeSelect */
.mantine-ComboboxChevron-chevron {
  opacity: 0;
  height: 5px;
}

.mantine-NativeSelect-input {
  background-color: transparent;
  color: #9daab4;
  outline: none;
}

@media (prefers-color-scheme: dark) {
  .mantine-NativeSelect-input {
    background-color: #092a43;
    color: #fff;
  }
}

.mantine-InputWrapper-root {
  background-color: transparent;
  border-color: #ced4d9;
}

.mantine-Modal-content {
  background-color: #fff;
  position: fixed;
  top: 20%;
  left: 30%;
  right: 30%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  padding: 20px;
  border-radius: 4%;
}

@media (prefers-color-scheme: dark) {
  .mantine-Modal-content {
    background-color: #092a43;
    color: #fff;
  }
}

@media (max-width: 600px) {
  .mantine-Modal-content {
    left: 10%;
    right: 10%;
  }
}

.mantine-Modal-close {
  position: absolute;
  height: 40px;
  font-size: smaller;
  top: 1%;
  right: 1%;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .mantine-Modal-close {
    color: #f08517;
  }
}

.mantine-Modal-title {
  padding-top: 4%;
}

.mode-switch {
  opacity: 1;
  transition: opacity 0.3s;
}

body.scrolled-down .mode-switch {
  opacity: 0;
  pointer-events: none;
}
